<template>
  <v-app>
    <NotificationContainer />
    <UpdateApp />

    <div class="fill-height">
      <v-container fluid class="fill-height" tag="section">
        <v-row justify="center" class="ae-row">
          <v-card flat class="transparent my-0">
            <v-card-text>
              <div class="text-center ae-client-portal-logo">
                <img src="/ae_logo.png" />
              </div>
            </v-card-text>
            <router-view />
          </v-card>
        </v-row>
      </v-container>
    </div>

    <div class="line x-line">
      <img src="/x_line.png" />
    </div>
    <div class="line o-line">
      <img src="/o_line.png" />
    </div>

    <dashboard-core-footer />
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout',
  components: {
    DashboardCoreFooter: () => import('../../components/core/Footer'),
    NotificationContainer: () =>
      import('@/components/core/NotificationContainer'),
    UpdateApp: () => import('@/components/core/UpdateApp'),
  },
};
</script>
